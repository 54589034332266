.admin-article-opt {
  margin-left: 10px;
}

.admin-article-opt-group {
  padding-right: 10px;
}

.admin-article-title-input {
  padding: 10px 5px 0 0;
}

.admin-article-title-view {
  padding: 10px 0 0 5px;
  font-size: 24px;
  height: 30px;
  text-align: center;
}

.admin-article-content-left {
  padding: 10px 5px 0 0;
  height: 600px;
  overflow: auto;
}

.admin-article-content-right {
  padding: 10px 0 0 5px;
  height: 600px;
  overflow: auto;
  blockquote {
    border-left: 5px solid #ddd;
    margin: 0 15px;
    padding: 0 15px;
    color: #666;
  }
}

.admin-article-content-textarea {
  height: 100%!important;
}

.admin-article-cover {
  .ant-upload-select {
    width: 160px!important;
    height: 90px!important;
  }
}

.admin-article-cover-img {
  width: 160px;
  height: 90px;
}

.admin-article-detail-line {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  line-height: 32px;
}

.admin-article-tag-select {
  min-width: 300px;
  max-width: 410px;
  .ant-select-selection-overflow-item {
    margin-top: 2px;
    margin-bottom: 2px;
  }
}
