.global-color {
  color: #292a2c;
}

.global-color-dark {
  color: #fff;
}

.global-color-danger {
  color: #ff4d4f;
}

.global-header {
  background: #fff;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  position: sticky;
  top: 0;
  z-index: 1;
}

.global-content {
  padding: 0 50px;
  background: #fff;
}

.global-content-child {
  min-height: 280px;
  padding: 24px;
  width: 1200px;
}

.global-footer {
  background: #fff;
  text-align: center;
}

.global-spin {
  margin-top: 25px;
}

.logo-img {
  width: 40px;
  margin-right: 20px;
}

.global-style-dark {
  background: #101010!important;
  color: #fff!important;
  border-bottom: .1px solid #fff!important;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.dis-block {
  display: block;
}

.dis-none {
  display: none!important;
}

.dis-inline {
  display: inline;
}

.text-center {
  text-align: center;
}

.float-right {
  float: right;
}

.height-full {
  height: 100%;
}

.width-full {
  width: 100%;
}

.cursor-auto {
  cursor: auto;
}

.global-comment-host-tag {
  margin-left: 8px;
}

.no-padding {
  padding:0!important;
}
