.article-common {
  // font-family: FK Roman Text,Times New Roman,sans-serif;
  // font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  letter-spacing: .25px;
  line-height: 36px;
  h1 {
    font-size: 2em;
    line-height: 1.2em;
  }
  blockquote {
    border-left: 5px solid #ddd;
    margin: 0 15px;
    padding: 0 15px;
    color: #666;
  }
}

.article-comment-form {
  border: 1px solid #f0f0f0;
  margin-top: 24px;
  padding: 10px;
}

.article-comment-sign {
  line-height: 32px;
  font-size: 16px;
}

.article-comment-sign-name {
  margin-left: 10px
}

.article-comment-divider {
  margin: 10px 0;
}

.article-comment-textarea {
  margin-top: 6px;
  height: 200px!important;
}

.article-comment-opt {
  margin: 0 0 2px 0
}

.article-comment-list-title {
  margin-top: 24px;
  font-size: 20px;
}

.article-comment-list {
  margin-top: 12px;
}

.article-comment-avatar {
  margin-right: 15px;
}

.article-comment-content {
  padding-top: 12px;
}

.article-comment-sublist {
  margin-top: 12px
}
