.home-main-background {
  // background-image: url('~/public/home-bg.png');
  background-image: url('https://file-to-qrcode.andycen.com/blog-resources/home-bg.png');
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.home-main-desc {
  display: flex;
  color: #fff;
  height: 100%;
  align-items: center;
  padding: 50px;
  font-size: 50px;
}
