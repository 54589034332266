.admin-layout-main {
  min-height: 100vh;
}

.admin-layout-logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.admin-layout-header {
  padding: 0 16px;
  background: #fff;
}

.admin-layout-content {
  margin: 0 16px;
}

.admin-layout-content-child {
  margin: 16px 0;
  padding: 24px;
  min-height: 360px;
  background: #fff; 
}
