.user-center-title {
  margin-bottom: 24px;
}

.user-center-line {
  display: flex;
  line-height: 32px;
  margin-bottom: 12px;
}

.user-center-opt {
  margin-left: 10px;
}

.user-center-input {
  width: 250px;
}

.user-center-avator-upload {
  margin-top: 8px;
}

.user-center-avator-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%
}
