.admin-archives-search {
  width: 250px;
}

.admin-archives-search-button {
  margin-left: 15px;
}

.admin-archives-item {
  display: flex!important;
  padding-left: 0!important;
  padding-right: 0!important;
}

.admin-archives-item-img {
  object-fit: fill;
  width: 160px;
  height: 90px;
  cursor: pointer;
}

.admin-archives-item-right {
  margin-left: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.admin-archives-item-right-top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.admin-archives-item-title {
  font-size: 22px;
}

.admin-archives-item-title:hover {
  color: #1677ff;
}

.admin-archives-item-date {
  color: #999;
  font-size: 12px;
}

.admin-archives-item-tag-icon {
  margin-right: 6px;
}

.admin-archives-item-tag:hover {
  color: #1677ff;
  background: #e6f4ff;
  // cursor: pointer;
}
