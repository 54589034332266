.login-main {
  margin-top: 150px;
}

.login-dialog {
  width: 300px;
}

.login-function {
  margin-bottom: 12px;
  text-align: right;
}

.login-function-reg {
  margin-left: 12px;
}

.login-verify-code-btn {
  margin-left: 8px
}
